import flattenDeep from 'lodash/flattenDeep';
import { matchPath, useLocation } from 'react-router-dom';

const ROUTES = {
  index: '/',
  root: '/:store',
  styleGuide: '/:store/styles',
  dashboard: {
    base: '/:store/dashboard',
    preview: '/:store/dashboard-preview/:gender/:dateFrom/:dateTo',
    share: '/:store/share-dashboard',
    containers: {
      create: '/:store/dashboard/container/create',
      edit: '/:store/dashboard/container/:id/edit',
      byId: '/:store/dashboard/container/:id',
    },
    edit: '/:store/dashboard/edit',
    archive: '/:store/dashboard/archive',
  },
  landingPage: {
    base: '/:store/landing-page',
    create: '/:store/landing-page/create',
    edit: '/:store/landing-page/edit/:id',
  },
  administration: {
    base: '/:store/administration',
    create: '/:store/administration/create',
    edit: '/:store/administration/edit/:id',
  },
  newsBanner: { base: '/:store/news-banner' },
  faqs: {
    base: '/:store/faqs',
    create: '/:store/faqs/:parentId?/create/',
    edit: '/:store/faqs/:parentId?/edit/:id',
    preview: '/:store/faqs/:parentId/preview/',
  },
  nutritions: {
    base: '/:store/nutritions',
    create: '/:store/nutritions/create',
    edit: '/:store/nutritions/:id/edit',
  },
  category: {
    base: '/:store/category',
    create: '/:store/category/create',
    edit: '/:store/category/:id/edit',
  },
  subcategory: {
    base: '/:store/sub-category/:taxonomyId/:categoryContainerId',
    create: '/:store/sub-category/:taxonomyId/:categoryContainerId/create',
    edit: '/:store/sub-category/:taxonomyId/:categoryContainerId/edit/:id',
  },
  categoryContainer: {
    base: '/:store/category-container',
    create: '/:store/category-container/create',
    edit: '/:store/category-container/:taxonomyContainerId/edit/:id',
    categoryEdit: '/:store/category-container/:id/edit',
  },
  categoryTag: {
    base: '/:store/category-tag',
    create: '/:store/category-tag/create',
    edit: '/:store/category-tag/:categoryId/edit/:id',
    preview: '/:store/category-tag/:id',
  },
  regulations: {
    base: '/:store/regulations',
    create: '/:store/regulations/create',
    edit: '/:store/regulations/:id/edit',
  },
  regulationElements: {
    base: '/:store/regulation-elements/:regulationId',
    create: '/:store/regulation-elements/:regulationId/create/',
    edit: '/:store/regulation-elements/:regulationId/edit/:id',
  },
  marketing: { base: '/:store/marketing' },
  promotionActions: { base: '/:store/promotion-actions' },
  auth: {
    login: '/:store/login',
    reset: '/:store/reset-password',
    change: '/:store/change-password',
  },
  minibanners: {
    base: '/:store/minibanners',
    create: '/:store/minibanners/create',
    edit: '/:store/minibanners/:id/edit',
  },
  onboardScreens: {
    base: '/:store/onboard-screens',
    create: '/:store/onboard-screens/create',
    edit: '/:store/onboard-screens/:id/edit',
  },
  abouts: {
    base: '/:store/abouts',
    create: '/:store/abouts/create',
    edit: '/:store/abouts/:id/edit',
  },
  announcements: {
    base: '/:store/announcements',
    create: '/:store/announcements/create',
    edit: '/:store/announcements/:id/edit',
  },
  splashScreens: {
    base: '/:store/splash-screens',
    create: '/:store/splash-screens/create',
    edit: '/:store/splash-screens/:id/edit',
  },
  leaderCategories: {
    base: '/:store/leader-categories',
    create: '/:store/leader-categories/create',
    edit: '/:store/leader-categories/:id/edit',
  },
  benefitsScreens: {
    base: '/:store/benefits-screens',
    create: '/:store/benefits-screens/create',
    edit: '/:store/benefits-screens/:id/edit',
  },
  benefitsScreenElements: {
    base: '/:store/benefits-screens/:benefitsScreenId',
    create: '/:store/benefits-screens/:benefitsScreenId/create',
    edit: '/:store/benefits-screens/:benefitsScreenId/edit/:id',
  },
  statistics: {
    base: '/:store/statistics',
  },
  sizings: {
    base: '/:store/sizings',
    create: '/:store/sizings/create',
    edit: '/:store/sizings/:id/edit',
  },
  clothingMachines: {
    base: '/:store/clothing-machines',
    create: '/:store/clothing-machines/create',
    edit: '/:store/clothing-machines/:id/edit',
  },
  users: {
    base: '/:store/users',
  },
  ratings: {
    base: '/:store/ratings',
  },
  inPostPoints: {
    base: '/:store/inpost-points',
  },
  dhlPoints: {
    base: '/:store/dhl-points',
  },
  blogPost: {
    base: '/:store/blog-post',
    create: '/:store/blog-post/create',
    edit: '/:store/blog-post/edit/:id',
  },
  notificationCategories: {
    base: '/:store/notification-categories',
    create: '/:store/notification-categories/create',
    edit: '/:store/notification-categories/edit/:id',
  },
  notifications: {
    base: '/:store/notifications',
    create: '/:store/notifications/create',
    edit: '/:store/notifications/edit/:id',
  },
  weatherCategories: {
    base: '/:store/weather-categories',
    edit: '/:store/weather-categories/edit/:id',
    preview: '/:store/weather-categories/:id',
    conditions: {
      edit: '/:store/weather-categories/:categoryId/edit/:id',
    },
  },
  subscriptionRunupScreens: {
    base: '/:store/subscription-runup-screens',
    create: '/:store/subscription-runup-screens/create',
    edit: '/:store/subscription-runup-screens/:id/edit',
    elements: {
      base: '/:store/subscription-runup-screens/:id/elements',
      create: '/:store/subscription-runup-screens/:id/elements/create',
      edit: '/:store/subscription-runup-screens/:screenId/elements/:id',
    },
  },
  appStoriesInspirations: {
    base: '/:store/app-stories-inspirations',
    edit: '/:store/app-stories-inspirations/:id/edit',
  },
  shipments: {
    base: '/:store/shipments',
    create: '/:store/shipments/create',
    edit: '/:store/shipments/:id/edit',
  },
  basketball: {
    teams: {
      base: '/:store/basketball/teams',
      edit: '/:store/basketball/teams/:id/edit',
    },
    pages: {
      base: '/:store/basketball/pages',
    },
  },
  surveys: {
    base: '/:store/surveys',
    create: '/:store/surveys/create',
    edit: '/:store/surveys/:id/edit',
    questions: {
      base: '/:store/surveys/:surveyId/questions',
      create: '/:store/surveys/:surveyId/questions/create',
      edit: '/:store/surveys/:surveyId/questions/:id/edit',
    },
  },
};

const transformRoutesForRouterMatch: any = (obj: { [key: string]: any }) => {
  const arr = Object.keys(obj).map((key) => {
    if (typeof obj[key] === 'string') {
      return { path: obj[key] };
    }
    return transformRoutesForRouterMatch(obj[key]);
  });

  return flattenDeep(arr);
};

export const ROUTES_FOR_MATCH = transformRoutesForRouterMatch(ROUTES);

export const useIsDashboardPreview = () => {
  const { pathname } = useLocation();
  return [ROUTES.dashboard.edit, ROUTES.dashboard.preview].some((path) =>
    matchPath(path, pathname),
  );
};

export default ROUTES;
