import { Box } from '@chakra-ui/react';
import Card from 'components/Card';
import Input from 'components/Form/Input';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';
import ToggleCheckbox from 'components/Form/ToggleCheckbox';

interface HeaderFormProps {
  isEdit?: boolean;
}

function HeaderForm({ isEdit }: HeaderFormProps) {
  return (
    <Box mb="8px">
      <Card>
        <Box maxW={FORM_BODY_MAX_WIDTH}>
          <Input name="title" label="Tytuł" mb="26px" />
          {isEdit && <Input name="slug" label="Slug" />}

          <ToggleCheckbox name="active" label="Aktywność" mt="32px" />
        </Box>
      </Card>
    </Box>
  );
}
HeaderForm.defaultProps = {
  isEdit: false,
};
export default HeaderForm;
