import { useEffect } from 'react';
import { Navigate, Outlet, useParams, generatePath } from 'react-router-dom';
import ROUTES from 'app/routes';
import { selectIsAuthenticated } from 'selectors/authSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import { useLazyGetUserQuery } from 'app/services/authApi';

function PrivateRoutes() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const [fetchUser] = useLazyGetUserQuery();

  const { store } = useParams();

  useEffect(() => {
    (async () => {
      if (isAuthenticated) {
        await fetchUser();
      }
    })();
  }, [fetchUser, isAuthenticated]);

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to={generatePath(ROUTES.auth.login, { store })} />
  );
}

export default PrivateRoutes;
