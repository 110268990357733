import {
  Box,
  Button,
  HStack,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import ThreeDots from 'components/Icons/3Dots';
import { getSelectedRows } from 'selectors/uiSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import CopyToGender from 'components/GenderCopy';
import {
  useBulkDeleteMutation,
  useBulkUpdateMutation,
} from 'app/services/dashboardApi';
import ActivityChange from 'components/ActivityChange';
import CountryCopyModal from 'components/CountryCopy';
import DeleteModal from 'components/DeleteModal';

function FooterButtons() {
  const [bulkDelete] = useBulkDeleteMutation();
  const [bulkUpdate] = useBulkUpdateMutation();

  const {
    isOpen: isGenderCopy,
    onClose: closeGenderCopy,
    onOpen: openGenderCopy,
  } = useDisclosure();

  const {
    isOpen: isActivityChange,
    onClose: closeActivityChange,
    onOpen: openActivityChange,
  } = useDisclosure();

  const {
    isOpen: isCountryCopyVisible,
    onClose: closeCountryCopy,
    onOpen: openCountryCopy,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onClose: closeDelete,
    onOpen: openDelete,
  } = useDisclosure();

  const selectedRows = useAppSelector(getSelectedRows);
  const isMenuDisabled = isEmpty(selectedRows);

  const handleBulkDelete = async () => {
    try {
      await bulkDelete({ dashboardContainerIds: selectedRows }).unwrap();
      toast.success('Pomyślnie usunięto wybrane komponenty');
      closeDelete();
    } catch (err) {
      toast.error('Wystąpił problem podczas usuwania komponentów');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  const handleBulkStatus = async (active: boolean) => {
    try {
      await bulkUpdate({
        dashboardContainerIds: selectedRows,
        dashboardContainer: { active },
      }).unwrap();
      toast.success('Zmiany zostały zastosowane');
    } catch (err) {
      toast.error('Wystąpił problem');
      // eslint-disable-next-line no-console
      console.error(err);
    }
  };

  return (
    <Box>
      <DeleteModal
        isOpen={isDeleteOpen}
        onClose={closeDelete}
        onAccept={handleBulkDelete}
      >
        Czy na pewno chcesz usunąć wybrane komponenty?
      </DeleteModal>
      <CopyToGender onClose={closeGenderCopy} isOpen={isGenderCopy} />
      <CountryCopyModal
        onClose={closeCountryCopy}
        isOpen={isCountryCopyVisible}
      />
      <ActivityChange onClose={closeActivityChange} isOpen={isActivityChange} />
      <HStack>
        <Button
          variant="link"
          onClick={() => handleBulkStatus(true)}
          isDisabled={isMenuDisabled}
        >
          AKTYWUJ
        </Button>
        <Button
          variant="link"
          onClick={() => handleBulkStatus(false)}
          isDisabled={isMenuDisabled}
        >
          DEZAKTYWUJ
        </Button>
        <Button
          variant="link"
          isDisabled={isMenuDisabled}
          onClick={openActivityChange}
        >
          ZMIEŃ DATĘ
        </Button>
        <Box>
          <Menu>
            <MenuButton
              as={IconButton}
              variant="unstyled"
              aria-label="Extra options"
              icon={<ThreeDots color="white" />}
            />
            <MenuList>
              <MenuItem isDisabled={isMenuDisabled} onClick={openDelete}>
                Usuń
              </MenuItem>
              <MenuItem isDisabled={isMenuDisabled} onClick={openGenderCopy}>
                Kopiuj do płci
              </MenuItem>
              <MenuItem isDisabled={isMenuDisabled} onClick={openCountryCopy}>
                Kopiuj do kraju
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </HStack>
    </Box>
  );
}

export default FooterButtons;
