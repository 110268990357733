import { ColumnDef } from '@tanstack/react-table';

function GetColumns(): ColumnDef<BannerItem, string>[] {
  return [
    {
      header: 'POZYCJA',
      accessorKey: 'position',
      cell: (info) => info.getValue(),
    },
    {
      header: 'NAZWA',
      accessorKey: 'title',
      cell: (info) => info.getValue(),
    },
    {
      header: 'DZISIAJ',
      accessorKey: 'todayValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'WCZORAJ',
      accessorKey: 'yesterdayValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OSTATNIE 7 DNI',
      accessorKey: 'weekValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'OKRES',
      accessorKey: 'rangeValue',
      cell: (info) => info.getValue(),
    },
    {
      header: 'POPRZEDNI OKRES',
      accessorKey: 'shadowValue',
      cell: (info) => info.getValue(),
    },
    {
      header: '',
      accessorKey: 'action',
    },
  ];
}

export default GetColumns;
