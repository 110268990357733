import { useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { FaRegTrashAlt } from 'react-icons/fa';
import { isEmpty } from 'lodash';
import { IconButton, Box, Spinner, Flex, Text } from '@chakra-ui/react';

import { matchPath, useLocation } from 'react-router-dom';
import Select from 'components/Form/Select';
import { OptionType } from 'types/common';
import ParamsInput from 'components/LinkCreator/ParamsInput';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import {
  useLazyBlogPostsLinksQuery,
  useLazyCategoryLinksQuery,
  useLazyFaqElementsQuery,
  useLazyLandingPageLinksQuery,
  useLazyMagentoFiltersQuery,
  useLazyRegulationsElementsQuery,
} from 'app/services/linkCreatorApi';
import LandingPageAnchorSelect from 'components/LinkCreator/LandingPageAnchorSelect';
import ROUTES from 'app/routes';

const LINK_TYPES_WITH_SUGGESTIONS = [
  'badge[]=param_value',
  'badge_label=param_value',
  'landing_page=param_value',
  'categoryId=param_value',
  'gender[]=param_value',
  'categoryIds[]=param_value',
  'regulation_element=param_value',
  'blog_post=param_value',
  'faqId=param_value',
];

const OPERATOR_OPTION = [
  { label: 'OR', value: 'OR' },
  { label: 'AND', value: 'AND' },
];

const ROUTES_WITH_ANCHOR_SELECT = [
  ROUTES.dashboard.containers.create,
  ROUTES.dashboard.containers.edit,
  ROUTES.landingPage.create,
  ROUTES.landingPage.edit,
  ROUTES.blogPost.create,
  ROUTES.blogPost.edit,
];

const INBOX_ROUTES = [ROUTES.notifications.create, ROUTES.notifications.edit];

interface LinkParametersProps {
  prepareFieldName: (name: string) => string;
  linkTypes: OptionType[];
  index: number;
  isFetching: boolean;
  handleRemove?: (index: number) => void;
  isRemovable: boolean;
  fieldNamePrefix?: string;
}

function LinkParameters({
  linkTypes,
  prepareFieldName,
  index,
  isFetching,
  handleRemove,
  isRemovable,
  fieldNamePrefix,
}: LinkParametersProps) {
  const { pathname } = useLocation();

  const preparedFieldName = prepareFieldName('');
  const hideAnchorsSelect =
    !ROUTES_WITH_ANCHOR_SELECT.some((path) => matchPath(path, pathname)) ||
    fieldNamePrefix ||
    preparedFieldName.includes('elementsAttributes');
  const isInboxForm = INBOX_ROUTES.some((path) => matchPath(path, pathname));

  const { control, getValues, setValue } = useFormContext();

  const [
    fetchCategories,
    {
      data: categories,
      isFetching: fetchingCategories,
      isSuccess: fetchedCategories,
    },
  ] = useLazyCategoryLinksQuery();
  const [
    fetchLandingPages,
    {
      data: landingPages,
      isFetching: fetchingLandingPages,
      isSuccess: fetchedLandingPages,
    },
  ] = useLazyLandingPageLinksQuery();
  const [
    fetchBlogPosts,
    {
      data: blogPosts,
      isFetching: fetchingBlogPosts,
      isSuccess: fetchedBlogPosts,
    },
  ] = useLazyBlogPostsLinksQuery();
  const [
    fetchMagentoBadges,
    {
      data: magentoBadges,
      isFetching: fetchingMagentoBadges,
      isSuccess: fetchedMagentoBadges,
    },
  ] = useLazyMagentoFiltersQuery();
  const [
    fetchMagentoGenders,
    {
      data: magentoGenders,
      isFetching: fetchingMagentoGenders,
      isSuccess: fetchedMagentoGenders,
    },
  ] = useLazyMagentoFiltersQuery();
  const [
    fetchRegulationElements,
    {
      data: regulationElements,
      isFetching: fetchingRegulationElements,
      isSuccess: fetchedRegulationElements,
    },
  ] = useLazyRegulationsElementsQuery();
  const [
    fetchFaqs,
    { data: faqs, isFetching: fetchingFaqs, isSuccess: fetchedFaqs },
  ] = useLazyFaqElementsQuery();

  const fetchingDropdown = useMemo(
    () =>
      fetchingCategories ||
      fetchingLandingPages ||
      fetchingMagentoBadges ||
      fetchingMagentoGenders ||
      fetchingRegulationElements ||
      fetchingBlogPosts ||
      fetchingFaqs,
    [
      fetchingCategories,
      fetchingLandingPages,
      fetchingMagentoBadges,
      fetchingMagentoGenders,
      fetchingRegulationElements,
      fetchingBlogPosts,
      fetchingFaqs,
    ],
  );

  const fetchedDropdown = useMemo(
    () =>
      fetchedCategories ||
      fetchedLandingPages ||
      fetchedMagentoBadges ||
      fetchedMagentoGenders ||
      fetchedRegulationElements ||
      fetchedBlogPosts ||
      fetchedFaqs,
    [
      fetchedCategories,
      fetchedLandingPages,
      fetchedMagentoBadges,
      fetchedMagentoGenders,
      fetchedRegulationElements,
      fetchedBlogPosts,
      fetchedFaqs,
    ],
  );

  const baseFieldName = useMemo(() => {
    const base = `linkParametersAttributes[${index}]`;
    if (fieldNamePrefix) {
      return `${fieldNamePrefix}.${base}`;
    }

    return base;
  }, [fieldNamePrefix, index]);

  const linkParametersOperatorFieldName = useMemo(() => {
    const base = 'linkParametersOperator';
    if (fieldNamePrefix) {
      return `${fieldNamePrefix}.${base}`;
    }

    return base;
  }, [fieldNamePrefix]);

  const [linkType, hidden, linkParamValue] = useWatch({
    control,
    name: [
      prepareFieldName(`${baseFieldName}.linkType`),
      prepareFieldName(`${baseFieldName}._destroy`),
      prepareFieldName(`${baseFieldName}.linkParamValue`),
    ],
  });

  const shouldRenderParamsInput = useMemo(
    () => linkType?.includes('param_value'),
    [linkType],
  );

  const isMultipleValueInput = useMemo(
    () => linkType?.includes('[]'),
    [linkType],
  );

  useEffect(() => {
    // badges
    if (linkType?.includes('badge')) {
      fetchMagentoBadges('badge');
    }

    // landing page
    if (linkType?.includes('landing_page')) {
      fetchLandingPages({ only_active: isInboxForm });
    }

    // category
    if (linkType?.includes('category') || linkType?.includes('categoryIds')) {
      fetchCategories();
    }

    // gender
    if (linkType?.includes('gender')) {
      fetchMagentoGenders('gender');
    }

    // regulation elements
    if (linkType?.includes('regulation_element')) {
      fetchRegulationElements();
    }

    // blog posts
    if (linkType?.includes('blog_post')) {
      fetchBlogPosts();
    }

    // faqs
    if (linkType?.includes('faqId')) {
      fetchFaqs();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fetchCategories,
    fetchLandingPages,
    fetchMagentoBadges,
    fetchMagentoGenders,
    fetchRegulationElements,
    fetchBlogPosts,
    fetchFaqs,
    index,
    linkType,
    isInboxForm,
  ]);

  const dropdownOptions = useMemo(() => {
    if (LINK_TYPES_WITH_SUGGESTIONS.includes(linkType)) {
      // magento badges
      if (linkType?.includes('badge')) {
        return magentoBadges || [];
      }

      // landing page
      if (linkType?.includes('landing_page')) {
        return landingPages || [];
      }

      // category
      if (linkType?.includes('category') || linkType?.includes('categoryIds')) {
        return categories || [];
      }

      // gender
      if (linkType?.includes('gender')) {
        return magentoGenders || [];
      }

      // regulation elements
      if (linkType?.includes('regulation_element')) {
        return regulationElements || [];
      }

      // blog posts
      if (linkType?.includes('blog_post')) {
        return blogPosts || [];
      }

      // faqs
      if (linkType?.includes('faqId')) {
        return faqs || [];
      }
    }

    return [];
  }, [
    categories,
    landingPages,
    linkType,
    magentoBadges,
    magentoGenders,
    regulationElements,
    blogPosts,
    faqs,
  ]);

  useEffect(() => {
    if (!isEmpty(dropdownOptions)) {
      const linkParamField = prepareFieldName(
        `${baseFieldName}.linkParamValue`,
      );

      const values = getValues(linkParamField);
      const hasValidOptions = Array.isArray(values)
        ? values.every((value: string) =>
            dropdownOptions.find((option: any) => option.value === value),
          )
        : dropdownOptions.some((option: any) => option.value === values);

      if (!hasValidOptions) {
        setValue(linkParamField, null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOptions, linkType, index]);

  const clearLandingPageFields = () => {
    setValue(prepareFieldName('relatedLpContainerElement'), null);
    setValue(prepareFieldName('relatedLandingPageId'), null);
  };

  if (hidden) {
    return null;
  }

  return (
    <Box>
      <Box mb={shouldRenderParamsInput ? 0 : 4} pos="relative">
        {isRemovable && typeof handleRemove === 'function' && (
          <IconButton
            aria-label="remove section"
            icon={<FaRegTrashAlt size={16} />}
            variant="unstyled"
            size="md"
            type="button"
            onClick={() => handleRemove(index)}
            pos="absolute"
            right={0}
            top="-10px"
            zIndex={3}
          />
        )}
        <Select
          name={prepareFieldName(`${baseFieldName}.linkType`)}
          options={linkTypes}
          placeholder="Wybierz rodzaj linku"
          label="Rodzaj linku"
          isLoading={isFetching}
          isClearable
          onChangeCallback={() => {
            setValue(prepareFieldName(`${baseFieldName}.linkParamValue`), null);
            setValue(prepareFieldName(linkParametersOperatorFieldName), null);
            clearLandingPageFields();
          }}
        />
      </Box>

      {!fetchingDropdown ? (
        <>
          {shouldRenderParamsInput &&
            (!LINK_TYPES_WITH_SUGGESTIONS.includes(linkType) ||
              (fetchedDropdown && !dropdownOptions.length)) && (
              <>
                {linkType.includes('bundleId') && (
                  <Box
                    mt={6}
                    backgroundColor="complementary.purpleBlue"
                    p="24px 40px 24px 16px"
                  >
                    Wartość parametru dla parametru "Produkty komplementarne"
                    znajdziesz we Flareonie.
                  </Box>
                )}
                <ParamsInput
                  name={prepareFieldName(`${baseFieldName}.linkParamValue`)}
                  isMultipleValueInput={isMultipleValueInput}
                />
              </>
            )}

          {/*
        autocomplete tag input
      {shouldRenderParamsInput &&
        linkType === 'categoryIds[]=param_value' &&
        dropdownOptions.length && (
          <ParamsInput
            name={prepareFieldName(`${baseFieldName}.linkParamValue`)}
            isMultipleValueInput={isMultipleValueInput}
            suggestions={dropdownOptions}
          />
        )} */}

          {shouldRenderParamsInput && dropdownOptions.length > 0 && (
            <Box mt="42px">
              <Select
                name={prepareFieldName(`${baseFieldName}.linkParamValue`)}
                options={dropdownOptions || []}
                placeholder="Wpisz, lub wybierz z listy"
                label="Wartość parametru"
                isLoading={isFetching}
                isMulti={isMultipleValueInput}
                hideSelectedOptions={false}
                onChangeCallback={clearLandingPageFields}
              />
              {linkType?.includes('categoryIds') && (
                <Box mb={8} mt={8}>
                  <Text variant="sectionTitle">Operator łączący elementy</Text>
                  <Flex mt={4}>
                    {OPERATOR_OPTION.map((option, idx) => (
                      <RadioBoxControl
                        key={option.value}
                        id={`${prepareFieldName(
                          linkParametersOperatorFieldName,
                        )}-option-${idx}`}
                        label={option.label}
                        name={prepareFieldName(linkParametersOperatorFieldName)}
                        value={option.value}
                        isClearable
                        group
                      />
                    ))}
                  </Flex>
                </Box>
              )}
              {!hideAnchorsSelect &&
                linkType?.includes('landing_page') &&
                linkParamValue && (
                  <Box mb={8} mt={8}>
                    <LandingPageAnchorSelect
                      prepareFieldName={prepareFieldName}
                      id={linkParamValue}
                    />
                  </Box>
                )}
            </Box>
          )}
        </>
      ) : (
        <Flex mt="42px" h="74px" alignItems="center">
          <Spinner />
        </Flex>
      )}
    </Box>
  );
}

LinkParameters.defaultProps = {
  fieldNamePrefix: null,
  handleRemove: null,
};

export default LinkParameters;
