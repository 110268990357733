import { Navigate } from 'react-router-dom';
import { Box, Center, Flex, Text } from '@chakra-ui/react';
import ROUTES from 'app/routes';
import LoginForm from 'pages/Login/LoginForm';
import { selectIsAuthenticated } from 'selectors/authSelectors';
import { useAppSelector } from 'utils/reduxHooks';
import BrandLogo from 'components/Icons/BigInPost';
import useRoute from 'utils/useRoute';

function Login() {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const dashboardRoute = useRoute(ROUTES.dashboard.base);

  if (isAuthenticated && dashboardRoute) {
    return <Navigate to={dashboardRoute} replace />;
  }

  return (
    <Flex direction="row" h="100%" w="100%">
      <Center w="718px" bg="black">
        <BrandLogo />
      </Center>
      <Flex
        w="100%"
        align="center"
        justify="center"
        marginX="30px"
        flex={1}
        direction="column"
      >
        <Box>
          <Text w="100%" variant="h1" pb="42px">
            ZALOGUJ SIĘ
          </Text>
          <LoginForm />
        </Box>
      </Flex>
    </Flex>
  );
}

export default Login;
