import { useMemo } from 'react';
import isNull from 'lodash/isNull';
import { Box, BoxProps, HStack } from '@chakra-ui/react';
import {
  ACTIVE_FROM,
  ACTIVE_TO,
  KIND,
  STATUS,
} from 'pages/Dashboard/Listing/constants';
import FilterBadge from 'components/FilterBadge';
import useQueryParams from 'utils/useQueryParams';

type FilterBoxProps = BoxProps;

function FilterBox({ children }: FilterBoxProps) {
  const { search } = useQueryParams();

  const amount = useMemo(() => {
    let counter = 0;
    const activeFrom = search.get(ACTIVE_FROM);
    const activeTo = search.get(ACTIVE_TO);
    counter += search.getAll(KIND).length;
    counter += search.get(STATUS) ? 1 : 0;
    counter += !isNull(activeFrom) && activeFrom.length > 1 ? 1 : 0;
    counter += !isNull(activeTo) && activeTo.length > 1 ? 1 : 0;

    return counter;
  }, [search]);

  return (
    <HStack spacing="56px">
      <Box position="relative">
        {children}
        {amount > 0 && <FilterBadge amount={amount} />}
      </Box>
    </HStack>
  );
}

export default FilterBox;
