import { Link } from 'react-router-dom';
import { Box } from '@chakra-ui/react';
import ROUTES from 'app/routes';
import {
  useDeleteLandingPageMutation,
  useLandingPagesQuery,
} from 'app/services/landingPageApi';
import useQueryParams from 'utils/useQueryParams';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import Footer, { FOOTER_HEIGHT } from 'components/Footer';
import LoadingIndicator from 'components/LoadingIndicator';
import ListingLayout from 'components/Layout/ListingLayout';
import Table from 'pages/LandingPage/Listing/components/Table';
import SearchIndicator from 'components/Search/SearchIndicator';
import { SEARCH_KEY } from 'pages/LandingPage/Listing/constants';
import FilterBox from 'pages/LandingPage/Listing/components/FilterBox';
import FooterButtons from 'pages/LandingPage/Listing/components/FooterButtons';
import FiltersDrawer from 'pages/LandingPage/Listing/components/FiltersDrawer';
import useRoute from 'utils/useRoute';

function LandingPages() {
  const createLandingPagePath = useRoute(ROUTES.landingPage.create);

  const { queryString, isSearchActive } = useQueryParams({
    page_size: '10',
    page: '1',
    order: 'DESC',
  });

  const [, state] = useDeleteLandingPageMutation({
    fixedCacheKey: 'landingPage',
  });

  const {
    isFetching,
    data: { data: landingPages, totals } = { data: [], totals: 0 },
  } = useLandingPagesQuery(queryString);

  const { isLoading } = state;
  const showLoading = isFetching || isLoading;

  const additionalPadding = isSearchActive(SEARCH_KEY) ? '183px' : '128px';

  return (
    <ListingLayout
      title="Kontent"
      filterDrawer={FiltersDrawer}
      searchKey={SEARCH_KEY}
      placement="landing_page"
      headerButtons={[
        { label: 'NOWY KONTENT', as: Link, to: createLandingPagePath },
      ]}
      FilterBox={FilterBox}
    >
      {showLoading && <LoadingIndicator />}
      {!showLoading && (
        <Box>
          <SearchIndicator searchKey={SEARCH_KEY} />
          <Table
            data={landingPages}
            h={`calc(100vh - (${NAVBAR_HEIGHT} + ${FOOTER_HEIGHT} + ${additionalPadding}));`}
          />
        </Box>
      )}
      <Footer totals={totals} footerButtons={<FooterButtons />} />
    </ListingLayout>
  );
}

export default LandingPages;
