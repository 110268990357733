import {
  Box,
  DrawerBody as ChakraBody,
  Flex,
  HStack,
  Text,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { endOfDay, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import useQueryParams from 'utils/useQueryParams';
import RadioBoxControl from 'components/Form/RadioBoxControl';
import DatePicker from 'components/Form/Date';
import { useFormWithSchema } from 'utils/formHooks';
import {
  SCHEMA,
  RANGE_OPTIONS,
  TIME_FROM,
  TIME_TO,
  RANGE,
  RANGE_DICT,
} from 'pages/Statistics/TopBanners/components/FiltersDrawer/constants';
import ExternalError from 'components/Form/ExternalError';

interface FilterBodyProps {
  onClose: () => void;
}

function FilterBody({ onClose }: FilterBodyProps) {
  const { search, setSearch } = useQueryParams();

  const getDefaultValues = () => {
    const timeFrom = search.get(TIME_FROM);
    const timeTo = search.get(TIME_TO);
    const range = search.get(RANGE);

    const result: { [key: string]: any } = {};
    result.range = range || undefined;
    result.timeFrom = timeFrom ? new Date(timeFrom) : undefined;
    result.timeTo = timeTo ? new Date(timeTo) : undefined;

    return result;
  };

  const methods = useFormWithSchema(SCHEMA, {
    mode: 'onChange',
  });
  const { handleSubmit, setValue, reset, control } = methods;

  const onSubmit = handleSubmit(async (data) => {
    const { range: newRange, timeFrom: newTimeFrom, timeTo: newTimeTo } = data;

    if (newRange) search.set(RANGE, newRange);
    else search.delete(RANGE);

    if (newTimeFrom) search.set(TIME_FROM, new Date(newTimeFrom).toISOString());
    else search.delete(TIME_FROM);

    if (newTimeTo) search.set(TIME_TO, new Date(newTimeTo).toISOString());
    else search.delete(TIME_TO);

    setSearch(search);
    onClose();
  });

  useEffect(() => {
    reset(getDefaultValues());
    // eslint-disable-next-line
  }, []);

  const [range] = useWatch({
    control,
    name: [RANGE],
  });

  useEffect(() => {
    const today = new Date();
    if (range === RANGE_DICT.MONTH) {
      setValue(TIME_FROM, startOfMonth(startOfDay(today)));
      setValue(TIME_TO, endOfDay(today));
    }
    if (range === RANGE_DICT.WEEK) {
      setValue(TIME_FROM, startOfWeek(startOfDay(today)));
      setValue(TIME_TO, endOfDay(today));
    }
  }, [range, setValue]);

  return (
    <ChakraBody overflowX="hidden" overflowY="auto">
      <FormProvider {...methods}>
        <Box as="form" id="filter-form" onSubmit={onSubmit} w="452px">
          <Flex direction="column">
            <Text fontWeight={400} fontSize="14px">
              Okres
            </Text>
            <Flex direction="row" p="8px">
              {RANGE_OPTIONS.map((el, idx) => (
                <RadioBoxControl
                  label={el.label}
                  name={RANGE}
                  id={`range_option_${idx}`}
                  key={`range_option_${el.value}`}
                  value={el.value}
                  group
                />
              ))}
            </Flex>
            <ExternalError name={RANGE} />
            <HStack pt="8px" spacing="16px">
              <DatePicker
                name={TIME_FROM}
                label="Data od"
                showError
                rangeSelect={false}
                onSelect={() => setValue(RANGE, RANGE_DICT.CUSTOM)}
              />
              <DatePicker
                name={TIME_TO}
                label="Data do"
                showError
                rangeSelect={false}
                onSelect={() => setValue(RANGE, RANGE_DICT.CUSTOM)}
              />
            </HStack>
          </Flex>
        </Box>
      </FormProvider>
    </ChakraBody>
  );
}

export default FilterBody;
