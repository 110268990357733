import { Box, Flex, Text } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import Headers from 'components/Container/Previews/Headers';
import Button from 'components/Container/Previews/Button';
import { SlideProps, Element } from 'types/previews';
import TaggedProductsPreview from 'components/Container/Previews/TaggedProductsPreview';

interface SlideContentProps extends SlideProps<Element> {
  fallback?: JSX.Element | null;
  showStats?: boolean;
  props?: any;
}

function SlideContent({
  fallback,
  showStats,
  element,
  props,
}: SlideContentProps) {
  return (
    <Box {...props}>
      <Headers {...element} />
      {fallback}
      {element.buttonText ? (
        <Box position="absolute" left="0" right="0" bottom="0">
          <Button {...element} />
        </Box>
      ) : null}
      {(element?.withTags || !isEmpty(element?.photoTags)) && (
        <TaggedProductsPreview formValues={element} showStats={showStats} />
      )}
      <Flex
        position="absolute"
        justifyContent="end"
        alignItems="center"
        bottom={5}
        right={20}
      >
        <Text
          fontSize="12px"
          fontWeight={400}
          textAlign="left"
          maxW={286}
          color="white"
          whiteSpace="nowrap"
        >
          {element.description}
        </Text>
      </Flex>
    </Box>
  );
}

SlideContent.defaultProps = {
  fallback: undefined,
  props: undefined,
  showStats: false,
};

export default SlideContent;
