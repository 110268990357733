import 'swiper/css';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';

import { useEffect, useMemo, useState } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Center,
  Text,
  Box,
} from '@chakra-ui/react';
import Stories from 'react-insta-stories';
import SwiperCore, { EffectCube } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Dashboard } from 'types/dashboard';
import { useLandingPageQuery } from 'app/services/landingPageApi';
import Headers from 'components/Container/Previews/Headers';
import Button from 'components/Container/Previews/Button';

function StoryPlaceholder({ isFetching }: { isFetching: boolean }) {
  return [
    {
      content: () => (
        <Center width="100%" height="100%">
          <Text variant="h1" color="#fff">
            {isFetching ? 'Ładowanie' : 'Wybierz źródło'}
          </Text>
        </Center>
      ),
    },
  ];
}

type StoriesModalProps = {
  currentIndex: number;
  setCurrentIndex: (index: number) => void;
  elements: Dashboard[];
  showStats: boolean;
};

function StoriesModal({
  currentIndex,
  setCurrentIndex,
  elements,
  showStats,
}: StoriesModalProps) {
  const [swiperRef, setSwiperRef] = useState<SwiperCore>();
  const onClose = () => setCurrentIndex(-1);

  useEffect(() => {
    if (swiperRef && !swiperRef.destroyed) {
      swiperRef.slideTo(currentIndex);
    }
  }, [currentIndex, swiperRef]);

  const timestamp = useMemo(() => Date.now(), []);

  const linkParameters = showStats
    ? elements?.[currentIndex]?.linkParameters
    : elements?.[currentIndex]?.linkParametersAttributes;
  const lpId = linkParameters?.[0]?.linkParamValue;
  const { data: landingPage, isFetching } = useLandingPageQuery(
    `${lpId!}?t=${timestamp}`,
    { skip: !lpId },
  );

  const itemStories = useMemo(() => {
    const elementsAttributes =
      landingPage?.containersAttributes?.[0]?.elementsAttributes;

    const stories = elementsAttributes?.map((el: Dashboard) => {
      const type = el.video?.url ? 'video' : 'image';
      const buttonText = el?.buttonText;

      return {
        url: type === 'video' ? el.video.url : el.image.url,
        type,
        seeMore: () => null,
        // eslint-disable-next-line react/no-unstable-nested-components
        seeMoreCollapsed: () => (
          <>
            <Box marginTop="-384px" position="relative">
              <Headers {...(el as any)} />
            </Box>
            {buttonText && (
              <Box position="absolute" left="0" right="0" bottom="44px">
                <Button {...(el as any)} />
              </Box>
            )}
          </>
        ),
      };
    });

    return lpId && elementsAttributes?.length > 0
      ? stories
      : StoryPlaceholder({ isFetching });
  }, [landingPage, lpId, isFetching]);

  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent
        sx={{
          display: 'flex',
          justifyContent: 'center',
          maxWidth: '432px',
          backgroundColor: 'transparent',
        }}
      >
        <Swiper
          effect="cube"
          modules={[EffectCube]}
          onSwiper={setSwiperRef}
          onSlideChange={({ activeIndex }) => setCurrentIndex(activeIndex)}
          touchStartPreventDefault={false}
          style={{
            width: '100%',
          }}
        >
          {elements.map((_, index) => (
            <SwiperSlide
              // eslint-disable-next-line react/no-array-index-key
              key={`slide_${index}`}
              style={{
                width: '432px',
                height: '768px',
                backgroundColor: '#000',
              }}
            >
              {currentIndex === index && (
                <Stories
                  stories={itemStories}
                  isPaused={currentIndex !== index}
                  width="100%"
                  height="100%"
                  onAllStoriesEnd={() => {
                    if (currentIndex === elements.length - 1) {
                      onClose();
                    } else {
                      setCurrentIndex(currentIndex + 1);
                    }
                  }}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>
      </ModalContent>
    </Modal>
  );
}

export default StoriesModal;
