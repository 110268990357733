import { isNumber, round, sumBy } from 'lodash';
import qs from 'query-string';
import { differenceInCalendarDays, subDays } from 'date-fns';
import { api } from 'app/services/api';

const STORIES = [
  'Tour de InPost',
  'ArtinPost',
  'Pieski',
  'InPost Pay',
  'Pan Kleks',
  'Q&A',
  'InPost na wesoło',
  'InPost na poważnie',
  'InPost na sportowo',
  'InPost na zdrowie',
  'InPost na wakacje',
  'InPost na zimę',
  'InPost na wiosnę',
  'InPost na lato',
  'InPost na jesień',
  'InPost na święta',
  'InPost na weekend',
  'InPost na poniedziałek',
  'InPost na wtorek',
  'InPost na środę',
  'InPost na czwartek',
  'InPost na piątek',
  'InPost na sobotę',
  'InPost na niedzielę',
  'InPost na poranek',
  'InPost na popołudnie',
  'InPost na wieczór',
  'InPost na noc',
  'InPost dla każdego',
  'InPost dla Ciebie',
  'InPost dla mnie',
  'InPost dla nas',
  'InPost dla Was',
  'InPost dla nich',
  'InPost dla niego',
  'InPost dla niej',
];

export const statisticsApi = api.injectEndpoints({
  endpoints: (build) => ({
    statistics: build.query<StatisticsResponse, string>({
      query: (queryParams) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/element-conversion.json?${queryParams}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      keepUnusedDataFor: 10,
    }),
    topSearchPhrases: build.query<TransformedTopSearchPhraseResponse, string>({
      query: (queryParams) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/top-search-phrases.json${queryParams}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      transformResponse: (data: SearchPhrase[]) => {
        const valuesSum = sumBy(data, 'value');
        const percentDeltaSum = sumBy(data, (item) =>
          isNumber(item.percentDelta) ? item.percentDelta : 0,
        );

        return {
          data,
          valuesSum,
          percentDeltaSum: round(percentDeltaSum * 100, 2),
        };
      },
      keepUnusedDataFor: 10,
    }),
    topBanners: build.query<BannerItem[], string>({
      query: (queryParams) => {
        const params = qs.parse(queryParams);
        const startDate = new Date(params.timeFrom as string);
        const endDate = new Date(params.timeTo as string);
        const diff = differenceInCalendarDays(endDate, startDate) + 1;

        const payload = qs.stringify({
          ...params,
          shadowTimeFrom: subDays(startDate, diff).toISOString(),
          shadowTimeTo: subDays(endDate, diff).toISOString(),
        });

        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/top-banners.json?${payload}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      transformResponse: (data: BannerItem[]) => {
        return data?.slice(0, 20).map((item) => ({
          ...item,
          title: STORIES[Math.floor(Math.random() * STORIES.length)],
        }));
      },
      keepUnusedDataFor: 10,
    }),
    nextSteps: build.query<NextStepItem[], string>({
      query: (queryParams) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/top-search-phrases/next-step.json${queryParams}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      keepUnusedDataFor: 10,
    }),
    appstoryClicks: build.query<number, string>({
      query: (queryParams) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/appstory-clicks-per-product.json?${queryParams}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      keepUnusedDataFor: 10,
    }),
    elementClick: build.query<ElementClicksResponse, string>({
      query: (queryParams) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/element-clicks.json?${queryParams}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      keepUnusedDataFor: 10,
    }),
    firstLastBannerClick: build.query<FirstLastBannerClickResponse, string>({
      query: (analyticsId) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/first-last-banner-click.json?analyticsId=${analyticsId}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      keepUnusedDataFor: 10,
    }),
    topBannersComparison: build.query<TopBannersComparisonItem[], string>({
      query: (queryParams) => {
        return {
          url: `${process.env.REACT_APP_ANALYTICS_API_URL}/top-banners/comparison.json${queryParams}`,
          method: 'GET',
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_ANALYTICS_API_TOKEN}`,
          },
        };
      },
      keepUnusedDataFor: 10,
    }),
  }),
});

export const {
  useStatisticsQuery,
  useTopSearchPhrasesQuery,
  useTopBannersQuery,
  useNextStepsQuery,
  useAppstoryClicksQuery,
  useElementClickQuery,
  useFirstLastBannerClickQuery,
  useTopBannersComparisonQuery,
} = statisticsApi;
