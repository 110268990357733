import { Icon, IconProps } from '@chakra-ui/react';

function BrandLogo(props: IconProps) {
  return (
    <Icon
      width="86px"
      height="52px"
      viewBox="0 0 443 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <style>{'.cls-2{fill-rule:evenodd;stroke-width:0;fill:#ffcc05}'}</style>
      </defs>
      <g id="InPost_RGB_Yellow_Black">
        <path
          d="M264.73 138.08c3.71-3.68 5.57-8.63 5.57-14.72 0-6.39-1.85-11.43-5.48-15.07-3.71-3.63-9.01-5.47-15.92-5.47h-21.35v59.7h9.96v-18.88h12.08c6.34 0 11.43-1.84 15.14-5.56Zm-14.97-25.58c6.94 0 10.44 3.63 10.44 10.86 0 3.37-.95 6-2.8 7.88-1.85 1.89-4.49 2.8-7.81 2.8h-12.12v-21.55h12.3Zm-79.59-9.55h-9.97v59.7h9.97v-59.7Zm207.58 60.67c1.72 0 3.41-.22 5-.61h.05v-8.24c-.83.12-1.67.18-2.5.18-2.5 0-4.14-.57-4.92-1.62-.65-.83-.99-2.54-.99-5.17v-24.18h8.58v-8.01h-8.58v-12.92h-9.67v45.72c0 5.48.69 9.15 2.11 11.13 1.77 2.5 5.39 3.73 10.91 3.73Zm-169.74-.88h9.79v-30.31c0-5.08-1.42-9.03-4.18-11.83-2.76-2.85-6.47-4.51-11.13-4.51-10.74 0-22.65 2.67-22.65 2.67v43.97h9.79v-36.92c3.41-.75 8.11-1.45 11.04-1.23 4.75.4 7.33 3.64 7.33 9.64v28.51ZM316.45 139c0 7.18-1.98 12.97-5.95 17.35-3.97 4.38-9.11 6.57-15.4 6.57s-11.48-2.19-15.4-6.57c-3.97-4.38-5.95-10.16-5.95-17.34s1.98-12.96 5.95-17.3c3.97-4.33 9.1-6.53 15.4-6.53s11.52 2.19 15.49 6.53c3.88 4.38 5.86 10.12 5.86 17.3Zm-10.09.04c0-4.51-1.03-8.06-3.06-10.69-2.07-2.63-4.79-3.94-8.2-3.94s-6.13 1.32-8.19 3.98c-2.07 2.67-3.06 6.22-3.06 10.65s1.03 8.06 3.06 10.69c2.07 2.63 4.79 3.94 8.19 3.94s6.13-1.32 8.2-3.94c2.03-2.63 3.06-6.18 3.06-10.69Zm47.41 19.37c3.45-2.89 5.18-6.61 5.18-11.12 0-7.01-4.27-11.3-12.86-12.92-3.62-.66-7.29-1.32-11-1.89-2.63-.7-3.92-2.01-3.92-3.9 0-3.28 2.85-4.9 8.58-4.9 4.48 0 8.5 1.58 11.99 4.73l5.82-6.22c-4.83-4.56-10.65-6.83-17.51-6.83-5.65 0-10.18 1.36-13.59 4.12-3.41 2.75-5.09 6.22-5.09 10.29s1.38 7.05 4.18 9.07c2.42 1.66 6.38 2.89 12 3.72 4.66.74 7.51 1.31 8.58 1.71 1.85.7 2.76 2.01 2.76 3.9 0 1.62-.82 3.02-2.42 4.12s-3.75 1.66-6.51 1.66c-4.92 0-9.24-1.84-12.95-5.52l-7.42 6.31c4.83 5.35 11.65 8.01 20.49 8.01 5.65 0 10.22-1.44 13.67-4.33Z"
          style={{
            strokeWidth: 0,
            fillRule: 'evenodd',
            fill: '#1d1d1d',
          }}
        />
        <path
          d="M95.37 133.3s-7.82 3.03-17.47 3.03-17.47-3.03-17.47-3.03 7.82-3.03 17.47-3.03 17.47 3.03 17.47 3.03ZM119.52 88.77s-6.93-4.68-12.33-12.53c-5.39-7.86-7.21-15.92-7.21-15.92s6.93 4.67 12.33 12.53c5.4 7.86 7.21 15.92 7.21 15.92ZM101.83 107.98s-8.35-.93-16.87-5.38c-8.52-4.45-13.98-10.73-13.98-10.73s8.35.93 16.87 5.38c8.52 4.45 13.98 10.73 13.98 10.73ZM119.52 177.67s-6.93 4.68-12.33 12.53c-5.39 7.85-7.21 15.92-7.21 15.92s6.93-4.67 12.33-12.53c5.4-7.85 7.21-15.92 7.21-15.92ZM101.83 158.46s-8.35.93-16.87 5.38c-8.52 4.45-13.98 10.73-13.98 10.73s8.35-.93 16.87-5.38 13.98-10.73 13.98-10.73ZM122.11 147.89c6.29 16.11 16.4 27.66 34.46 30.4-2.06.28-4.15.45-6.29.46-25.59.11-46.44-20.17-46.55-45.3s20.54-45.6 46.13-45.71c2.28-.01 4.52.15 6.72.45-20.69 2.58-34.49 18.64-37.22 39.08-.87 12.46 13.64 16.67 13.64 16.67s-5.94 3.88-10.89 3.96Z"
          className="cls-2"
        />
        <path
          d="M0 0h443v266H0z"
          style={{
            fill: 'none',
            strokeWidth: 0,
          }}
        />
      </g>
    </Icon>
  );
}

export default BrandLogo;
