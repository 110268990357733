import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  HStack,
  VStack,
} from '@chakra-ui/react';
import { MdOutlineCancel } from 'react-icons/md';
import qs from 'query-string';
import * as yup from 'yup';
import { useMemo, useEffect } from 'react';
import { FormProvider, useWatch } from 'react-hook-form';
import { endOfDay, startOfDay } from 'date-fns';
import { NAVBAR_HEIGHT } from 'components/Layout/Navbar';
import DatePicker from 'components/Form/Date';
import {
  useElementClickQuery,
  useFirstLastBannerClickQuery,
} from 'app/services/statisticsApi';
import FormSpinner from 'components/FormSpinner';
import { useFormWithSchema } from 'utils/formHooks';
import Chart from 'pages/Statistics/TopBanners/components/ChartDrawer/Chart';

const SCHEMA = yup.object({
  date: yup.object({
    start: yup.date().nullable(),
    end: yup.date().nullable(),
  }),
});

interface ChartDrawerProps {
  banner: BannerItem | null;
  onClose: () => void;
}

function ChartDrawer({ banner, onClose }: ChartDrawerProps) {
  const { isFetching: isFetchingDates, data: dates } =
    useFirstLastBannerClickQuery(banner?.analyticsId!, {
      skip: !banner,
    });

  const dateRange = useMemo(
    () => ({
      start: dates?.firstDate ? startOfDay(new Date(dates?.firstDate)) : null,
      end: dates?.lastDate ? endOfDay(new Date(dates?.lastDate)) : null,
    }),
    [dates?.firstDate, dates?.lastDate],
  );

  const methods = useFormWithSchema(SCHEMA, {
    mode: 'onChange',
  });
  const { control, reset } = methods;

  const date = useWatch({
    control,
    name: 'date',
  });

  useEffect(() => {
    if (dateRange) reset({ date: dateRange });
  }, [dateRange, reset]);

  const queryString = useMemo(
    () =>
      qs.stringify({
        timeFrom: date?.start
          ? startOfDay(new Date(date?.start)).toISOString()
          : null,
        timeTo: date?.end ? endOfDay(new Date(date?.end)).toISOString() : null,
        analyticsId: banner?.analyticsId,
        type: 'banner_click',
      }),
    [banner?.analyticsId, date?.end, date?.start],
  );

  const { data: { clicks = [] } = { data: [] }, isFetching: isFetchingClicks } =
    useElementClickQuery(queryString, {
      skip: !banner || !date?.start || !date?.end,
    });

  const isLoading = isFetchingClicks || isFetchingDates;

  return (
    <Drawer isOpen={!!banner} placement="right" onClose={onClose} size="xl">
      <DrawerOverlay zIndex={1} />
      <DrawerContent mt={`calc(${NAVBAR_HEIGHT} - 1px);`}>
        <DrawerHeader alignItems="center">
          SUMA WYŚWIETLEŃ {banner?.title}
          <DrawerCloseButton>
            <MdOutlineCancel size="19px" />
          </DrawerCloseButton>
        </DrawerHeader>
        <DrawerBody>
          {isLoading ? (
            <FormSpinner />
          ) : (
            <VStack spacing={6} w="100%" align="start">
              <FormProvider {...methods}>
                <HStack spacing={4}>
                  <DatePicker
                    name="date.start"
                    label="Data początkowa"
                    showError
                    rangeSelect={false}
                  />
                  <DatePicker
                    name="date.end"
                    label="Data końcowa"
                    showError
                    rangeSelect={false}
                  />
                </HStack>
              </FormProvider>
              <Chart clicks={clicks || []} />
            </VStack>
          )}
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
export default ChartDrawer;
