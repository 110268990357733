/* eslint-disable no-plusplus */
import isNull from 'lodash/isNull';

const KEY_PREFFIX = 'inpost-stories';

const CLIENT_PREFIX = `${KEY_PREFFIX}_client`;
const TOKEN_PREFIX = `${KEY_PREFFIX}_token`;
export const UID_KEY = `${KEY_PREFFIX}_uid`;
export const TOKEN_TYPE_KEY = `${KEY_PREFFIX}_token_type`;
// export const STORE_KIND_KEY = '4f-cms-store';

export const addCredentials = (client: string, token: string) => {
  localStorage.setItem(CLIENT_PREFIX, client);
  localStorage.setItem(TOKEN_PREFIX, token);
};

export const getCredentials = () => {
  const client = localStorage.getItem(CLIENT_PREFIX);
  const token = localStorage.getItem(TOKEN_PREFIX);
  if (!isNull(client) && !isNull(token)) {
    return {
      client,
      token,
    };
  }
  return {
    client: '',
    token: '',
  };
};

export const clearCacheData = () => {
  localStorage.clear();
};

export const retrieveStorageData = (store: string) => {
  const credentials = getCredentials();

  return {
    accessToken: credentials?.token || null,
    client: credentials?.client || null,
    uid: localStorage.getItem(UID_KEY),
    tokenType: localStorage.getItem(TOKEN_TYPE_KEY),
    credentials,
    store,
  };
};
