import { Box } from '@chakra-ui/react';

interface LayoutProps {
  children: React.ReactNode;
}

function PublicLayout({ children }: LayoutProps) {
  return (
    <Box w="100%" h="100vh">
      {children}
    </Box>
  );
}

export default PublicLayout;
