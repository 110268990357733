import { Box } from '@chakra-ui/react';
import { Route, Outlet } from 'react-router-dom';
import map from 'lodash/map';
import DashboardListing from 'pages/Dashboard/Listing';
import DashboardArchive from 'pages/Dashboard/Archive';
import DashboardContainerCreate from 'pages/Dashboard/Containers/Create';
import StyleGuide from 'pages/StyleGuide';
import Login from 'pages/Login';
import PublicLayout from 'components/Layout/PublicLayout';
import Layout from 'components/Layout';
import ROUTES from 'app/routes';
import LandingPagesListing from 'pages/LandingPage/Listing';
import Reset from 'pages/Reset';
import ChangePassword from 'pages/ChangePassword';
import DashboardPreview from 'pages/Dashboard/Preview';
import DashboardContainerEdit from 'pages/Dashboard/Containers/Edit';
import DashboardEdit from 'pages/Dashboard/Edit';
import LandingPageCreate from 'pages/LandingPage/Form/Create';
import LandingPageEdit from 'pages/LandingPage/Form/Edit';
import DashboardSharePreview from 'pages/DashboardPreview';
import ShareDashboard from 'pages/ShareDashboard';
import Index from 'pages/Index';
import AdministrationListing from 'pages/Administration/Listing';
import AdministratorCreate from 'pages/Administration/Form/Create';
import AdministratorEdit from 'pages/Administration/Form/Edit';
import TopBanners from 'pages/Statistics/TopBanners';

export const renderRoutes = (routes: any[]) =>
  map(routes, ({ layout: RouteLayout = Box, ...route }, idx) => {
    const Component =
      typeof route.element === 'string' ? route.element : <route.element />;
    return (
      <Route
        key={`route-${route.path}-${idx}`}
        path={route.path}
        element={
          <RouteLayout>
            {Component}
            <Outlet />
          </RouteLayout>
        }
      >
        {route.children ? renderRoutes(route.children) : null}
      </Route>
    );
  });

export const PUBLIC_ROUTES = [
  { element: '404', path: '*', layout: PublicLayout },
  {
    element: Index,
    path: ROUTES.index,
    layout: PublicLayout,
  },
  { element: StyleGuide, path: ROUTES.styleGuide, layout: PublicLayout },
  { element: Login, path: ROUTES.auth.login, layout: PublicLayout },
  { element: Reset, path: ROUTES.auth.reset, layout: PublicLayout },
  { element: ChangePassword, path: ROUTES.auth.change, layout: PublicLayout },
  {
    element: DashboardSharePreview,
    path: ROUTES.dashboard.preview,
    layout: PublicLayout,
  },
];

export const PRIVATE_ROUTES = [
  {
    element: DashboardListing,
    path: ROUTES.dashboard.base,
    layout: Layout,
  },
  {
    element: DashboardArchive,
    path: ROUTES.dashboard.archive,
    layout: Layout,
  },
  {
    element: DashboardContainerCreate,
    path: ROUTES.dashboard.containers.create,
    layout: Layout,
  },
  {
    element: DashboardPreview,
    path: ROUTES.dashboard.containers.byId,
    layout: Layout,
  },
  {
    element: DashboardContainerEdit,
    path: ROUTES.dashboard.containers.edit,
    layout: Layout,
  },
  {
    element: DashboardEdit,
    path: ROUTES.dashboard.edit,
    layout: Layout,
  },
  {
    element: ShareDashboard,
    path: ROUTES.dashboard.share,
    layout: Layout,
  },
  {
    element: Index,
    path: ROUTES.root,
    layout: Layout,
  },
  {
    element: LandingPagesListing,
    path: ROUTES.landingPage.base,
    layout: Layout,
  },
  {
    element: 'News banner',
    path: ROUTES.newsBanner.base,
    layout: Layout,
  },
  {
    element: LandingPageCreate,
    path: ROUTES.landingPage.create,
    layout: Layout,
  },
  {
    element: LandingPageEdit,
    path: ROUTES.landingPage.edit,
    layout: Layout,
  },
  {
    element: AdministrationListing,
    path: ROUTES.administration.base,
    layout: Layout,
  },
  {
    element: AdministratorCreate,
    path: ROUTES.administration.create,
    layout: Layout,
  },
  {
    element: AdministratorEdit,
    path: ROUTES.administration.edit,
    layout: Layout,
  },
  {
    element: TopBanners,
    path: ROUTES.statistics.base,
    layout: Layout,
  },
];
