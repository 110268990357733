import { useEffect, useMemo, useState } from 'react';
import { Accordion, ExpandedIndex } from '@chakra-ui/react';
import { generatePath, useParams } from 'react-router-dom';
import {
  // MdDesktopWindows,
  MdOutlineBorderColor,
  MdOutlineAdminPanelSettings,
  MdOutlineCategory,
  MdQueryStats,
} from 'react-icons/md';
import { ExpandableNavOption, NavOption } from 'types/navigation';
import ExpandableNavItem from 'components/Navigation/ExpandableItem';
import NavItem from 'components/Navigation/Item';
import ROUTES from 'app/routes';

interface NavigationProps {
  isSidebarExpanded: boolean;
  expandSidebar: () => void;
}

function Navigation({ isSidebarExpanded, expandSidebar }: NavigationProps) {
  const [index, setIndex] = useState<ExpandedIndex>();
  const { store } = useParams();

  const NAV_OPTIONS: Array<NavOption | ExpandableNavOption> = useMemo(
    () => [
      // { title: 'Pulpit', icon: MdDesktopWindows, link: ROUTES.root },
      {
        title: 'Dashboard',
        icon: MdOutlineBorderColor,
        link: ROUTES.dashboard.base,
      },
      {
        title: 'Kontent',
        icon: MdOutlineCategory,
        link: ROUTES.landingPage.base,
      },
      {
        title: 'Administracja',
        icon: MdOutlineAdminPanelSettings,
        link: ROUTES.administration.base,
      },
      {
        title: 'Statystyki',
        icon: MdQueryStats,
        link: ROUTES.statistics.base,
      },
    ],
    [],
  );

  useEffect(() => {
    if (!isSidebarExpanded) setIndex(-1);
  }, [isSidebarExpanded]);

  return (
    <Accordion
      allowToggle
      h="100%"
      overflowX="hidden"
      overflowY="auto"
      index={index}
      onChange={(element) => setIndex(element)}
    >
      {NAV_OPTIONS.map((option, idx) => {
        if ('link' in option) {
          return (
            <NavItem
              key={`nav_option_${option.title}`}
              link={generatePath(option.link, { store })}
              title={option.title}
              icon={<option.icon size={24} />}
              idx={idx}
              isSidebarExpanded={isSidebarExpanded}
            />
          );
        }
        return (
          <ExpandableNavItem
            key={`nav_option_${option.title}`}
            options={option.options}
            title={option.title}
            icon={<option.icon size={24} />}
            idx={idx}
            isSidebarExpanded={isSidebarExpanded}
            expandSidebar={expandSidebar}
            store={store!}
          />
        );
      })}
    </Accordion>
  );
}

export default Navigation;
