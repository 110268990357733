import { useCallback, useMemo, FunctionComponent, useState } from 'react';
import { Flex, Box, Heading, Text, Button } from '@chakra-ui/react';
import { useWatch, useFormContext } from 'react-hook-form';
import { motion } from 'framer-motion';
import { BiPlus } from 'react-icons/bi';
import { useComponentTypesQuery } from 'app/services/commonApi';
import ComponentActions from 'pages/Dashboard/Containers/components/ComponentActions';
import ComponentPreview from 'pages/Dashboard/Containers/components/ComponentPreview';
import Card from 'components/Card';
import Select from 'components/Form/Select';
import FormSpinner from 'components/FormSpinner';
import {
  COMPONENT_HINTS,
  DEFAULT_FORM_VALUES,
  FormComponents,
} from 'components/Container/constants';
import { FormComponentProps } from 'types/dashboardCreator';
import ActivityFormSection from 'components/Container/Sections/ActivityFormSection';
import { FORM_BODY_MAX_WIDTH, FORM_MAX_WIDTH } from 'utils/constants';
import MarginFormSection from 'components/Container/Sections/MarginSection';
import NameInput from 'components/NameInput';
import AppVersionSection from 'components/Container/Sections/AppVersionSection';

interface ComponentWrapperProps {
  index: number;
  isLoading: boolean;

  multipleComponents: boolean;
  addComponent: () => void;
  removeComponent: (index: number) => void;
  showAddButton: boolean;
}

function ComponentWrapper({
  index,
  isLoading,
  multipleComponents,
  addComponent,
  removeComponent,
  showAddButton,
}: ComponentWrapperProps) {
  const { control, getValues, clearErrors, setValue, register } =
    useFormContext();
  const [isOpen, setIsOpen] = useState(true);

  const { data: { data: componentTypes } = { data: [] }, isFetching } =
    useComponentTypesQuery({ location: 'dashboard' });

  const fieldName = useMemo(() => `components[${index}]`, [index]);
  const prepareFieldName: (name: string) => string = useCallback(
    (name) => `${fieldName}.${name}`,
    [fieldName],
  );

  const removeComponentHandler = useCallback(() => {
    removeComponent(index);
  }, [index, removeComponent]);

  const componentKind = useWatch({
    control,
    name: prepareFieldName('kind'),
    defaultValue: '',
  });

  const FormComponent: FunctionComponent<FormComponentProps> =
    FormComponents[componentKind];

  const toggleSectionHandler = useCallback(() => setIsOpen(!isOpen), [isOpen]);

  const prepareFormWithDefaultValues = useCallback(
    async (kind: string) => {
      clearErrors();

      const defaultValues = getValues();

      if (defaultValues.components) {
        const newContainerValues = {
          ...(DEFAULT_FORM_VALUES[kind] || {}),
          name: defaultValues.components[index].name,
          kind,
          gender: 'all',
          userKind: 'both',
        };

        setValue(`components[${index}]`, newContainerValues);
      }
    },
    [clearErrors, getValues, index, setValue],
  );

  return (
    <Flex gap={4} mb={4} alignItems="flex-start">
      <Box flex={1} maxW={FORM_MAX_WIDTH} minW={650}>
        <Card>
          {isLoading && <FormSpinner />}
          <ComponentActions
            showTrashButton={multipleComponents}
            toggleAction={toggleSectionHandler}
            removeAction={removeComponentHandler}
            isOpen={isOpen}
          />

          <Box w="100%" pt={2}>
            <Heading
              as="h3"
              fontSize={16}
              borderBottom="1px solid"
              borderColor="complementary.grey"
              paddingBottom={0}
              maxW={FORM_BODY_MAX_WIDTH}
              sx={{
                '&:focus': {
                  outline: 'none',
                },
              }}
            >
              <NameInput type="text" {...register(prepareFieldName('name'))} />
            </Heading>

            <motion.div
              initial={{
                height: isOpen ? 'auto' : 0,
                overflow: isOpen ? 'visible' : 'hidden',
                paddingTop: isOpen ? '36px' : 0,
              }}
              animate={{
                height: isOpen ? 'auto' : 0,
                overflow: isOpen ? 'visible' : 'hidden',
                paddingTop: isOpen ? '36px' : 0,
              }}
            >
              <Box mb={4} maxW={FORM_BODY_MAX_WIDTH}>
                <Select
                  label="Typ komponentu"
                  name={prepareFieldName('kind')}
                  options={componentTypes}
                  isLoading={isFetching}
                  placeholder="Wybierz..."
                  onChangeCallback={prepareFormWithDefaultValues}
                />

                {Object.keys(COMPONENT_HINTS).includes(componentKind) ? (
                  <Text color="complementary.grey">
                    {
                      COMPONENT_HINTS[
                        componentKind as keyof typeof COMPONENT_HINTS
                      ]
                    }
                  </Text>
                ) : null}
              </Box>

              {componentKind && (
                <>
                  {FormComponent && (
                    <FormComponent
                      prepareFieldName={prepareFieldName}
                      isEditMode={false}
                    />
                  )}

                  <AppVersionSection prepareFieldName={prepareFieldName} />
                  <MarginFormSection prepareFieldName={prepareFieldName} />
                  <ActivityFormSection prepareFieldName={prepareFieldName} />
                </>
              )}
            </motion.div>
          </Box>
        </Card>

        {showAddButton && (
          <Button type="button" mt={4} w="100%" onClick={addComponent}>
            DODAJ KONTENER
            <Box as="span" ml={2}>
              <BiPlus size={24} />
            </Box>
          </Button>
        )}
      </Box>

      <ComponentPreview
        componentTypes={componentTypes}
        kind={componentKind}
        componentIndex={index}
      />
    </Flex>
  );
}

export default ComponentWrapper;
