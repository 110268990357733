import { Flex } from '@chakra-ui/react';
import BrandLogo from 'components/Icons/InPost';

interface HeaderProps {
  isExpanded: boolean;
}
function Header({ isExpanded }: HeaderProps) {
  return (
    <Flex
      w="100%"
      align="center"
      justify="space-between"
      minH="128px"
      pt={isExpanded ? '0' : '46px'}
      px={isExpanded ? '36px' : '0'}
      pb={isExpanded ? '0' : '12px'}
      direction={isExpanded ? 'row' : 'column'}
    >
      <BrandLogo />
    </Flex>
  );
}

export default Header;
