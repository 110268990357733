import { useMemo, useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import update from 'immutability-helper';
import * as yup from 'yup';
import qs from 'query-string';
import { Box, Flex, HStack, Text, useDisclosure } from '@chakra-ui/react';
import { FormProvider, useWatch } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import HeaderNavigation from 'components/HeaderNavigation';
import useQueryParams from 'utils/useQueryParams';
import {
  useDashboardsQuery,
  useDeleteDashboardMutation,
} from 'app/services/dashboardApi';
import { useFormWithSchema } from 'utils/formHooks';
import Card from 'components/Card';
import ROUTES from 'app/routes';
import SectionTitle from 'components/Form/SectionTitle';
import Date from 'components/Form/Date';
import DashboardContainer from 'pages/Dashboard/Edit/components/DashboardContainer';
import MobilePreview from 'pages/Dashboard/Edit/components/MobilePreview';
import LoadingCover from 'components/LoadingCover';
import DeleteModal from 'components/DeleteModal';
import { Dashboard } from 'types/dashboard';
import { transformActivityDates } from 'utils/date';
import { FORM_MAX_WIDTH } from 'utils/constants';
import useRoute from 'utils/useRoute';

const TRANSFORMED_KEYS: { [key: string]: string } = {
  activeFrom: 'date_from',
  activeTo: 'date_to',
};

interface DragItem {
  index: number;
  id: string;
  type: string;
  image?: any;
}

type Item = {
  id: number;
  name: string | null;
};

const schema = yup.object({
  page_size: yup.number(),
  page: yup.number(),
  gender: yup.mixed().oneOf(['male', 'female', 'girls', 'boys']),
  dateFrom: yup.date().nullable(),
  dateTo: yup.date().nullable(),
});

function DashboardEdit() {
  const { search } = useQueryParams();
  const navigate = useNavigate();
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [containerId, setContainerId] = useState<number | null>();
  const dashboardPath = useRoute(ROUTES.dashboard.base);
  const dashboardEditPath = useRoute(ROUTES.dashboard.edit);

  const [deleteContainer] = useDeleteDashboardMutation({
    fixedCacheKey: 'dashboard',
  });

  const defaultValues = {
    page_size: 1000,
    page: 1,
    'q[status_in][]': ['active', 'planned'],
    gender: search.get('gender') || 'male',
  };

  const methods = useFormWithSchema(schema, {
    mode: 'onChange',
    defaultValues,
  });

  const filters = useWatch({ control: methods.control });

  // transformActivityDates;
  const queryString: string = useMemo(() => {
    const f = transformActivityDates(filters);
    const filtersTransformed: { [key: string]: string | number } = {};

    Object.keys(f).forEach((key) => {
      const k = TRANSFORMED_KEYS[key] || key;
      if (f[key] !== ' ') {
        filtersTransformed[k] = f[key];
      }
    });

    return `?${qs.stringify(filtersTransformed)}`;
  }, [filters]);

  const onSubmit = () => {};

  const {
    isFetching,
    isSuccess,
    data: { data } = { data: [], totals: 0 },
  } = useDashboardsQuery(queryString);

  const [containers, setContainers] = useState<Item[]>(data || []);

  // update gender query parameter
  useEffect(() => {
    navigate(`${dashboardEditPath}?gender=${filters.gender}`, {
      replace: true,
    });
  }, [dashboardEditPath, filters, navigate]);

  useEffect(() => {
    if (isSuccess && data) {
      setContainers(data);
    }
  }, [isSuccess, data]);

  const moveItem = useCallback(
    (item: DragItem, dragIndex: number, hoverIndex: number) => {
      setContainers((prevCards: Item[]) =>
        update(prevCards, {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, prevCards[dragIndex] as Item],
          ],
        }),
      );
    },
    [],
  );

  const handleDelete = async () => {
    try {
      if (containerId) {
        await deleteContainer(containerId).unwrap();
        toast.success('Pomyślnie usunięto kontener');
        setContainerId(null);
        onClose();
      }
    } catch (error) {
      toast.error('Wystąpił problem podczas usuwania kontenera');
    }
  };

  const openDeleteConfirmationModal = (id: number) => {
    setContainerId(id);
    onOpen();
  };

  return (
    <Box pt="32px" paddingBottom={88}>
      <DeleteModal onAccept={handleDelete} onClose={onClose} isOpen={isOpen}>
        Czy na pewno chcesz trwale usunąć ten komponent?
      </DeleteModal>

      {isFetching ? <LoadingCover /> : null}
      <HeaderNavigation
        baseCrumb={{ to: dashboardPath, label: 'Dashboard' }}
        crumbs={[{ label: 'Edytuj dashboard' }]}
      />
      <FormProvider {...methods}>
        <form id="dashboard-editor" onSubmit={methods.handleSubmit(onSubmit)}>
          <Flex gap={4} mb={4} alignItems="flex-start">
            <Box flex={1} maxW={FORM_MAX_WIDTH}>
              <Card>
                <Box as="fieldset">
                  <SectionTitle as="legend" mb={22}>
                    Zakres dat dashboardu
                  </SectionTitle>
                  <HStack gap={4} mb="44px">
                    <Date
                      name="dateFrom"
                      label="Aktywny od"
                      showError
                      rangeSelect={false}
                    />
                    <Date
                      name="dateTo"
                      label="Aktywny do"
                      showError
                      rangeSelect={false}
                    />
                  </HStack>
                </Box>

                <Box>
                  <SectionTitle mb={5}>Kontenery dashboardu</SectionTitle>
                  <Text fontSize={14} mb="28px">
                    Przeciągnij i upuść, aby zmienić kolejność elementów na
                    dashboardzie
                  </Text>

                  <DndProvider backend={HTML5Backend}>
                    {containers.map((container, index) => (
                      <DashboardContainer
                        container={container}
                        key={container.id}
                        moveItem={moveItem}
                        index={index}
                        onDelete={openDeleteConfirmationModal}
                        gender={filters.gender}
                      />
                    ))}
                  </DndProvider>
                </Box>
              </Card>
            </Box>

            <MobilePreview containers={containers as Dashboard[]} />
          </Flex>
        </form>
      </FormProvider>
    </Box>
  );
}

export default DashboardEdit;
