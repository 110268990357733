/* eslint-disable no-underscore-dangle */
/* eslint-disable func-names */
import * as yup from 'yup';
import round from 'lodash/round';
import { AnyObject } from 'yup/lib/types';
import { differenceInSeconds } from 'date-fns';
import { toast } from 'react-toastify';
import isEmpty from 'lodash/isEmpty';
import { transformActivityDates } from 'utils/date';
import { ImageResponse } from 'types/common';
import { getVideoRatio, transformRemoved } from 'utils/helpers';

const KINDS_WITH_IMAGE_REQUIRED: string[] = [
  'graphic_tile_small',
  'graphic_tile',
  'text_banner_image',
  'frame_banner',
  'photo_banner_medium',
  'discount_banner',
  'paralax_banner',
  'square_banner',
  'last_chance',
  'basketball_banner',
];

const IMAGE_ASPECT_RATIO: { [key: string]: number | number[] } = {
  // graphic_tile_small: 3, // 360 x 120
  // graphic_tile: 0.68,
  // graphic_tile_carousel: 0.68,
  // text_banner_image: 1.45,
  // frame_banner: 0.88,
  // photo_banner_medium: 0.91,
  // discount_banner: 0.62,
  // square_banner: 1.24,
  // last_chance: 0.91, // 360 x 398
  // text_banner_slim: 4.44,
  // play_with_colors: 0.6,
  // paralax_banner_back: 0.56,
  // paralax_banner_front: 0.76,
  // image_gallery: [0.77, 0.7],
  // appstories: 1,
  // titled_carousel: 0.74,
  // stylization: 0.78,
};

const VIDEO_ASPECT_RATIO: { [key: string]: number | number[] } = {
  // video_banner: 0.68,
  // graphic_tile_carousel: 0.68,
  // image_gallery: [0.77, 0.7],
};

const KINDS_WITH_ELEMENTS: string[] = [
  'graphic_tile_carousel',
  'text_banner_slim',
  'image_gallery',
  'appstories',
  'play_with_colors',
  'titled_carousel',
  'stylization',
];

const KINDS_WITH_TITLE_REQUIRED: string[] = [
  'solid_button',
  'outlined_button',
  'label_button',
  'link_button',
  'products_list',
  'chosen_for_you',
  'external_link',
  'basketball_banner',
];

const KINDS_WITH_LINK_REQUIRED: string[] = [
  'graphic_tile',
  'text_banner_image',
  'video_banner',
  'photo_banner_medium',
  'square_banner',
  'frame_banner',
  'paralax_banner',
  'products_carousel',
  'products_list',
  'chosen_for_you',
  'solid_button',
  'outlined_button',
  'label_button',
  'link_button',
  'last_chance',
];

const KINDS_WITH_SUBTITLE: string[] = [
  'square_banner',
  'graphic_tile',
  'photo_banner_medium',
  'discount_banner',
  'paralax_banner',
  'last_chance',
  'play_with_colors',
];

const KINDS_WITH_BUTTON_TEXT_REQURIED = [
  'products_list',
  'paralax_banner',
  'basketball_banner',
];

const KIND_WITH_CONTENT_REQUIRED: string[] = [
  'text_banner_image',
  'text_banner',
];

const KINDS_WITH_SLIDES_VALIDATION = [
  'appstories',
  'graphic_tile_carousel',
  'image_gallery',
  'stylization',
  'titled_carousel',
];

const linkParametersSchema = {
  linkType: yup
    .string()
    .nullable()
    .test((value, context: any) => {
      const kind = context?.from?.[1]?.value?.kind;
      if (!value && kind?.includes('button')) {
        return context.createError({ message: 'Pole jest wymagane' });
      }
      return true;
    }),
  linkParamValue: yup
    .mixed()
    .nullable()
    .when(['linkType', '_destroy'], {
      is: (linkType: string, _destroy: boolean) =>
        linkType?.includes('param_value') && !_destroy,
      then: yup
        .mixed()
        .nullable()
        .test('has-value', 'Pole jest wymagane', (value) => !isEmpty(value)),
      otherwise: yup.mixed().nullable().notRequired(),
    }),
};

export const hasInvalidElementsLength = (
  container: any,
  limit = 1,
  message = 'Musisz dodać przynajmniej jeden element',
) => {
  if (
    KINDS_WITH_SLIDES_VALIDATION.includes(container?.kind) &&
    container?.elementsAttributes?.length < limit
  ) {
    toast.error(message, {
      toastId: 'elements-length-error',
    });
    return true;
  }

  return false;
};

export const validateActivityDates = (
  dateFrom: Date,
  timeFrom: Date,
  dateTo: Date,
  timeTo: Date,
) => {
  const { activeFrom, activeTo } = transformActivityDates({
    dateFrom,
    timeFrom,
    dateTo,
    timeTo,
  });

  const diff = differenceInSeconds(new Date(activeTo), new Date(activeFrom));

  return diff > 0;
};

const validateDatePersistance = (
  value: Date,
  testContext: yup.TestContext<AnyObject>,
) => {
  const { kind } = testContext.parent;

  if (['last_chance', 'timer_banner', 'survey'].includes(kind)) {
    return !!value;
  }

  return true;
};

export const validateImage = async (
  file: File | ImageResponse,
  kind: string,
  context: yup.TestContext<AnyObject>,
  isRequired: boolean,
) => {
  if (isRequired && !file) {
    return context.createError({ message: 'Zdjęcie jest wymagane' });
  }

  if ((file as File)?.name) {
    try {
      if (!Object.hasOwnProperty.call(file, 'url')) {
        if (IMAGE_ASPECT_RATIO[kind]) {
          const ratio = await createImageBitmap(file as File).then((bitmap) =>
            round(bitmap.width / bitmap.height, 2),
          );

          const expectedRatio = IMAGE_ASPECT_RATIO[kind];

          if (Array.isArray(expectedRatio)) {
            if (!expectedRatio.includes(ratio)) {
              return context.createError({
                message: `Zdjęcie ma nieprawidłowy rozmiar (wymagane ratio: ${expectedRatio.join(
                  ' lub ',
                )}, wybrane: ${ratio})`,
              });
            }
          } else if (ratio !== expectedRatio) {
            return context.createError({
              message: `Zdjęcie ma nieprawidłowy rozmiar (wymagane ratio: ${expectedRatio}, wybrane: ${ratio})`,
            });
          }
        }

        return true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug({ err });
    }
  }

  return true;
};

const validateVideo = async (
  file: File,
  kind: string,
  context: yup.TestContext<AnyObject>,
  isRequired: boolean,
) => {
  if (isRequired && !file) {
    return context.createError({ message: 'Video jest wymagane' });
  }

  if ((file as File)?.name) {
    try {
      if (!Object.hasOwnProperty.call(file, 'url')) {
        const expectedRatio = VIDEO_ASPECT_RATIO[kind];
        if (expectedRatio) {
          const ratio = await getVideoRatio(file);
          if (Array.isArray(expectedRatio)) {
            if (!expectedRatio.includes(ratio as number)) {
              return context.createError({
                message: `Video ma nieprawidłowy rozmiar (wymagane ratio: ${expectedRatio.join(
                  ' lub ',
                )}, wybrane: ${ratio})`,
              });
            }
          } else if (ratio !== expectedRatio) {
            return context.createError({
              message: `Video ma nieprawidłowy rozmiar (wymagane ratio: ${expectedRatio}, wybrane: ${ratio})`,
            });
          }
        }

        return true;
      }
    } catch (err) {
      // eslint-disable-next-line no-console
      console.debug({ err });
    }
  }

  return true;
};

const activityDatesSchema = {
  timeTo: yup
    .mixed()
    .test(
      'dateToLaterThanFrom',
      'Data końcowa musi być późniejsza od początkowej',
      function (value, testContext) {
        const { dateFrom, timeFrom, dateTo } = testContext.parent;

        if (dateFrom && value) {
          return validateActivityDates(dateFrom, timeFrom, dateTo, value);
        }
        return true;
      },
    )
    .test('timeToIsRequired', 'Pole jest wymagane', validateDatePersistance)
    .nullable(),
  dateTo: yup
    .mixed()
    .test(
      'dateToLaterThanFrom',
      'Data końcowa musi być późniejsza od początkowej',
      function (value, testContext) {
        const { dateFrom, timeFrom, timeTo } = testContext.parent;

        if (dateFrom && value) {
          return validateActivityDates(dateFrom, timeFrom, value, timeTo);
        }
        return true;
      },
    )
    .test('dateIsRequired', 'Pole jest wymagane', validateDatePersistance)
    .nullable(),
};

const componentSchema = yup.object({
  externalUrl: yup
    .string()
    .nullable()
    .when('kind', {
      is: 'external_link',
      then: yup.string().required('Pole jest wymagane'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  name: yup.string().nullable().notRequired(),
  userKind: yup.string().required('Pole jest wymagane'),
  kind: yup.string().required('Pole jest wymagane'),
  active: yup.boolean(),
  title: yup
    .string()
    .nullable()
    .when(['kind', 'withHeader'], {
      is: (kind: string, withHeader: boolean) =>
        (KINDS_WITH_TITLE_REQUIRED.includes(kind) || withHeader) &&
        !['last_chance', 'photo_banner_medium', 'video_banner'].includes(kind),
      then: yup.string().nullable().required('Pole jest wymagane'),
      otherwise: yup.string().when('withTitle', {
        is: (withTitle: boolean) => withTitle,
        then: yup.string().required('Pole jest wymagane'),
        otherwise: yup.string().nullable().notRequired(),
      }),
    }),
  subtitle: yup
    .string()
    .nullable()
    .when(['withHeader', 'kind'], {
      is: (withHeader: boolean, kind: string) =>
        withHeader && KINDS_WITH_SUBTITLE.includes(kind),
      then: yup.string().nullable().required('Pole jest wymagane'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  buttonKind: yup
    .string()
    .nullable()
    .when(['kind', 'withButton'], {
      is: (kind: string, withButton: boolean) =>
        ['products_carousel', 'titled_carousel'].includes(kind) && withButton,
      then: yup.string().nullable().required('Pole jest wymagane'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  titlePrefix: yup
    .string()
    .nullable()
    .when(['kind', 'withHeader'], {
      is: (kind: string, withHeader: boolean) =>
        kind === 'discount_banner' && withHeader,
      then: yup.string().nullable().required('Pole jest wymagane'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  buttonText: yup.string().when(['kind', 'withButton', 'buttonKind'], {
    is: (kind: string, withButton: boolean, buttonKind: string) =>
      KINDS_WITH_BUTTON_TEXT_REQURIED.includes(kind) ||
      (withButton && (!buttonKind || buttonKind === 'label')),
    then: yup.string().required('Pole jest wymagane'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  content: yup
    .string()
    .nullable()
    .when(['kind', 'withContent'], {
      is: (kind: string, withContent: boolean) =>
        (kind === 'timer_banner' && withContent) ||
        KIND_WITH_CONTENT_REQUIRED.includes(kind),
      then: (schema) => schema.required('Pole jest wymagane'),
    }),
  contentTextColor: yup.string().nullable(),
  insideBoxText: yup.string().when(['kind'], {
    is: (kind: string) => kind === 'discount_banner',
    then: yup.string().required('Pole jest wymagane'),
    otherwise: yup.string().nullable().notRequired(),
  }),
  productSKU: yup
    .string()
    .nullable()
    .when('kind', (kind) =>
      kind === 'play_with_colors'
        ? yup.string().required('Pole jest wymagane')
        : yup.string().nullable().notRequired(),
    ),
  hasSound: yup
    .boolean()
    .nullable()
    .when('kind', (kind) =>
      kind === 'video_banner'
        ? yup.string().required('Pole jest wymagane')
        : yup.string().nullable().notRequired(),
    ),
  linkParametersAttributes: yup
    .array()
    .test({
      test(array: any) {
        const categories =
          array?.filter(
            (item: any) =>
              !item?._destroy && item?.linkType?.includes('categoryId='),
          )?.length || 0;
        if (categories > 1) {
          toast.error(
            'W przypadku chęci dodania wielu kategorii użyj parametru "lista kategorii"',
            {
              toastId: 'categories-length-error',
            },
          );
        }
        return categories < 2;
      },
    })
    .when(['kind', 'withLinkParameters', 'withButton'], {
      is: (kind: string, withLinkParameters: boolean, withButton: boolean) =>
        KINDS_WITH_LINK_REQUIRED.includes(kind) ||
        withLinkParameters ||
        (['text_banner', 'titled_carousel', 'graphic_tile_small'].includes(
          kind,
        ) &&
          withButton),
      then: yup.array().of(yup.object().shape(linkParametersSchema)),
      otherwise: yup.array().nullable().notRequired(),
    }),
  linkParametersOperator: yup
    .string()
    .nullable()
    .when(['linkParametersAttributes', '_destroy'], {
      is: (linkParametersAttributes: any[], _destroy: boolean) =>
        linkParametersAttributes?.[0]?.linkType?.includes('categoryIds') &&
        !_destroy,
      then: yup.string().required('Pole jest wymagane'),
      otherwise: yup.string().nullable().notRequired(),
    }),
  photoTagsAttributes: yup.array().when(['withTags'], {
    is: (withTags: boolean) => withTags,
    then: yup.array().of(
      yup.object().shape({
        linkParamValue: yup.string().required('Pole jest wymagane'),
      }),
    ),
    otherwise: yup
      .array()
      .transform((values) => (values?.[0]?.id ? transformRemoved(values) : []))
      .nullable()
      .notRequired(),
  }),
  backgroundImage: yup.mixed().when('kind', (kind) =>
    kind === 'paralax_banner'
      ? yup.mixed().test({
          test(file) {
            return validateImage(file, `${kind}_back`, this, true);
          },
        })
      : yup.mixed().nullable().notRequired(),
  ),
  image: yup.mixed().when('kind', (kind) => {
    if (kind === 'text_banner_slim') {
      return yup.mixed().test({
        test(file) {
          return validateImage(file, kind, this, this.parent.type === 'image');
        },
      });
    }

    return KINDS_WITH_IMAGE_REQUIRED.includes(kind)
      ? yup.mixed().test({
          test(file) {
            return validateImage(
              file,
              // handler for paralax banner front / back image
              kind === 'paralax_banner' ? 'paralax_banner_front' : kind,
              this,
              true,
            );
          },
        })
      : yup.mixed().nullable().notRequired();
  }),
  video: yup.mixed().when('kind', (kind) =>
    kind === 'video_banner'
      ? yup.mixed().test({
          test(file) {
            return validateVideo(file, kind, this, true);
          },
        })
      : yup.mixed().nullable().notRequired(),
  ),
  ...activityDatesSchema,
  gender: yup.string(),
  backgroundColor: yup
    .string()
    .nullable()
    .when('kind', (kind) =>
      kind === 'appstories'
        ? yup.string().required('Pole jest wymagane')
        : yup.string().nullable().notRequired(),
    ),
  borderColor: yup.string().nullable().notRequired(),
  textHighlight: yup.string().nullable().notRequired(),
  specialElementsAttributes: yup.array().when('kind', (kind, currentSchema) => {
    if (kind === 'play_with_colors') {
      return yup.array().of(
        yup.object().shape({
          image: yup.mixed().test({
            test(file) {
              return validateImage(file, kind, this, false);
            },
          }),
        }),
      );
    }

    return currentSchema;
  }),
  elementsAttributes: yup.array().when('kind', (kind, currentSchema) => {
    if (KINDS_WITH_ELEMENTS.includes(kind)) {
      switch (kind) {
        case 'play_with_colors':
          return yup.array().of(
            yup.object().shape({
              image: yup.mixed().test({
                test(file) {
                  return validateImage(file, kind, this, false);
                },
              }),
            }),
          );
        case 'text_banner_slim':
          return yup.array().when('type', (type, sch) => {
            if (type === 'text') {
              return yup.array().of(
                yup.object().shape({
                  title: yup.string().required('Pole jest wymagane'),
                  ...activityDatesSchema,
                }),
              );
            }
            return sch;
          });
        case 'graphic_tile_carousel':
          return yup.array().of(
            yup.object().shape({
              alignment: yup
                .string()
                .nullable()
                .when('withButton', (withButton) =>
                  withButton
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              navigationColor: yup
                .string()
                .nullable()
                .required('Pole jest wymagane'),
              buttonText: yup
                .string()
                .nullable()
                .when('withButton', (withButton) =>
                  withButton
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              title: yup
                .string()
                .nullable()
                .when('withHeader', (withHeader) =>
                  withHeader
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              subtitle: yup
                .string()
                .nullable()
                .when('withHeader', (withHeader) =>
                  withHeader
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              description: yup
                .string()
                .nullable()
                .when('withSlideTitle', (withSlideTitle) =>
                  withSlideTitle
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              image: yup.mixed().when('type', (type) =>
                type === 'image'
                  ? yup.mixed().test({
                      test(file) {
                        return validateImage(file, kind, this, true);
                      },
                    })
                  : yup.mixed().nullable().notRequired(),
              ),
              video: yup.mixed().when('type', (type) =>
                type === 'video'
                  ? yup.mixed().test({
                      test(file) {
                        return validateVideo(file, kind, this, true);
                      },
                    })
                  : yup.mixed().nullable().notRequired(),
              ),
              linkParametersAttributes: yup
                .array()
                .of(yup.object().shape(linkParametersSchema)),
              ...activityDatesSchema,
              photoTagsAttributes: yup.array().when(['withTags'], {
                is: (withTags: boolean) => withTags,
                then: yup.array().of(
                  yup.object().shape({
                    linkParamValue: yup.string().required('Pole jest wymagane'),
                  }),
                ),
                otherwise: yup
                  .array()
                  .transform((values) =>
                    values?.[0]?.id ? transformRemoved(values) : [],
                  )
                  .nullable()
                  .notRequired(),
              }),
            }),
          );
        case 'image_gallery':
          return yup.array().when('type', (type) => {
            const baseValidations = {
              image: yup.mixed().test({
                test(file) {
                  return validateImage(file, kind, this, true);
                },
              }),
              video: yup.mixed().test({
                test(file) {
                  return validateVideo(file, kind, this, false);
                },
              }),
              deleteVideo: yup
                .mixed()
                .notRequired()
                .nullable()
                .transform((value) => +value),
              linkParametersAttributes: yup
                .array()
                .of(yup.object().shape(linkParametersSchema)),
              ...activityDatesSchema,
            };

            if (type === 'withHeaders') {
              return yup.array().of(
                yup.object().shape({
                  ...baseValidations,
                  title: yup.string().required('Pole jest wymagane'),
                }),
              );
            }

            return yup.array().of(yup.object().shape(baseValidations));
          });
        case 'appstories':
          return yup.array().of(
            yup.object().shape({
              image: yup.mixed().test({
                test(file) {
                  return validateImage(file, kind, this, true);
                },
              }),
              linkParametersAttributes: yup
                .array()
                .of(yup.object().shape(linkParametersSchema)),
              title: yup.string().nullable().required('Pole jest wymagne'),
              titleFontSize: yup
                .string()
                .nullable()
                .required('Pole jest wymagne'),
              textColor: yup.string().nullable().required('Pole jest wymagne'),
              ...activityDatesSchema,
            }),
          );
        case 'titled_carousel':
          return yup.array().of(
            yup.object().shape({
              image: yup.mixed().test({
                test(file) {
                  return validateImage(file, kind, this, true);
                },
              }),
              title: yup
                .string()
                .nullable()
                .when('withHeader', (withHeader) =>
                  withHeader
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              subtitle: yup
                .string()
                .nullable()
                .when('withHeader', (withHeader) =>
                  withHeader
                    ? yup.string().required('Pole jest wymagane')
                    : yup.string().nullable().notRequired(),
                ),
              linkParametersAttributes: yup
                .array()
                .of(yup.object().shape(linkParametersSchema)),
              ...activityDatesSchema,
            }),
          );
        case 'stylization':
          return yup.array().of(
            yup.object().shape({
              image: yup.mixed().test({
                test(file) {
                  return validateImage(file, kind, this, true);
                },
              }),
              photoTagsAttributes: yup.array().of(
                yup.object().shape({
                  linkParamValue: yup.string().required('Pole jest wymagane'),
                }),
              ),
            }),
          );
        default:
          return currentSchema;
      }
    }

    return currentSchema;
  }),
});

const schema = yup.object({
  components: yup.array().of(componentSchema),
});

export { schema, componentSchema, activityDatesSchema, linkParametersSchema };
