/* eslint-disable react/no-array-index-key */
import {
  flexRender,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { BoxProps } from '@chakra-ui/react';
import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import TabElement from 'components/Table/Table';
import Thead from 'components/Table/Thead';
import Tr from 'components/Table/Tr';
import Tbody from 'components/Table/Tbody';
import Th from 'components/Table/Th';
import columns from 'pages/Statistics/TopBanners/components/Table/columns';
import EmptyBody from 'components/Table/EmptyBody';
import TableWrapper from 'components/Table/TableWrapper';
import TableRow from 'pages/Statistics/TopBanners/components/Table/TableRow';
import Chart from 'pages/Statistics/TopBanners/components/ChartDrawer';

type TableProps = BoxProps & {
  data: BannerItem[];
};

function Table({ data, ...props }: TableProps) {
  const [selectedBanner, setSelectedBanner] = useState<BannerItem | null>(null);

  const table = useReactTable({
    data,
    columns: columns(),
    getCoreRowModel: getCoreRowModel(),
  });

  const noTableData = isEmpty(data);

  return (
    <TableWrapper {...props}>
      <Chart onClose={() => setSelectedBanner(null)} banner={selectedBanner} />
      <TabElement isEmpty={noTableData} id="top-banners-table">
        <Thead>
          <Tr style={{ outline: 'solid thin', borderBottomWidth: 0 }}>
            <Th colSpan={2} />
            <Th colSpan={4} style={{ padding: '4px 16px' }}>
              SUMA WYŚWIETLEŃ
            </Th>
            <Th colSpan={2} />
          </Tr>
          {table.getHeaderGroups().map((headerGroup) => (
            <Tr key={`header_row_${headerGroup.id}`}>
              {headerGroup.headers.map((header) => (
                <Th key={`header_cell_${header.id}`}>
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody>
          {noTableData ? (
            <EmptyBody
              columnAmount={table.getAllColumns().length}
              queryParams={[]}
            />
          ) : (
            table
              .getRowModel()
              .rows.map((row, index) => (
                <TableRow
                  key={`table_row_${index}`}
                  row={row}
                  onClick={() => setSelectedBanner(row.original)}
                />
              ))
          )}
        </Tbody>
      </TabElement>
    </TableWrapper>
  );
}

export default Table;
