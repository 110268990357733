import { HStack } from '@chakra-ui/react';
import { useFormContext, useWatch } from 'react-hook-form';
import OptionalFormFieldset from 'components/OptionalFormFieldset';
import TimeInput from 'components/Form/Time';
import DateInput from 'components/Form/Date';
import { FormComponentProps } from 'types/dashboardCreator';
import { FORM_BODY_MAX_WIDTH } from 'utils/constants';

interface ActivityFormSectionProps extends FormComponentProps {
  title?: string;
  isReadOnly?: boolean;
}

function ActivityFormSection({
  prepareFieldName,
  title,
  isReadOnly,
}: ActivityFormSectionProps) {
  const { control } = useFormContext();
  const isActive: boolean = useWatch({
    control,
    name: prepareFieldName('active'),
  });

  return (
    <OptionalFormFieldset
      title={title || 'Aktywność'}
      isActive={isActive}
      name={prepareFieldName('active')}
      sectionWidth={FORM_BODY_MAX_WIDTH}
      hint="Ustaw aktywność od daty bieżącej, zaplanuj wyświetlenie w przyszłości, lub pozostaw komponent nieaktywnym"
      isReadOnly={isReadOnly}
    >
      <HStack spacing={4} mb={6}>
        <DateInput
          name={prepareFieldName('dateFrom')}
          label="Aktywny od"
          showError
          rangeSelect={false}
        />
        <DateInput
          name={prepareFieldName('dateTo')}
          label="Aktywny do"
          showError
          rangeSelect={false}
        />
      </HStack>
      <HStack spacing={4} mb={6}>
        <TimeInput
          name={prepareFieldName('timeFrom')}
          label="Godzina rozpoczęcia"
          errorKey={prepareFieldName('activeDates')}
          showError
        />
        <TimeInput
          name={prepareFieldName('timeTo')}
          label="Godzina zakończenia"
          errorKey={prepareFieldName('timeTo')}
          showError
        />
      </HStack>
    </OptionalFormFieldset>
  );
}

ActivityFormSection.defaultProps = {
  title: null,
  isReadOnly: false,
};

export default ActivityFormSection;
