import styled from '@emotion/styled';

const Td = styled.td`
  padding-inline: 16px;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  height: 136px;
  vertical-align: top;

  :nth-of-type(1) {
    width: 64px;
    font-weight: 600;
    padding-top: 16px;
    text-align: center;
  }
  :nth-of-type(2) {
    width: 248px;
    padding-top: 16px;
  }
  :nth-of-type(3) {
    width: 248px;
    padding-top: 16px;
  }
  :nth-of-type(4) {
    padding: 0px;
    width: 128px;
  }
  :nth-of-type(5) {
    padding-top: 16px;
    width: 196px;
  }
  :nth-of-type(6) {
    text-align: right;
  }
`;

export default Td;
